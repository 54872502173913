// Constants for Action calls
export const SET_ID = "SET_ID";
export const LOAD_NETWORK_SUCCESS = "LOAD_NETWORK_SUCCESS";
export const SET_LAYERS_EXTREMES = "SET_LAYERS_EXTREMES";
export const LOAD_CODE_SUCCESS = "LOAD_CODE_SUCCESS";
export const UPDATE_CODE_SUCESS = "UPDATE_CODE_SUCCESS";
export const MOVE_GROUP = "MOVE_GROUP";
export const MOVE_LEGEND = "MOVE_LEGEND";
export const ZOOM_GROUP = "ZOOM_GROUP";
export const ZOOM_LEGEND = "ZOOM_LEGEND";
export const TOGGLE_CODE = "TOGGLE_CODE";
export const TOGGLE_PREFERENCES = "TOGGLE_PREFERENCES";
export const TOGGLE_LEGEND = "TOGGLE_LEGEND";
export const TOGGLE_ALERT = "TOGGLE_ALERT";
export const TOGGLE_HELP = "TOGGLE_HELP";
export const TOGGLE_UPLOAD = "TOGGLE_UPLOAD";
export const LOAD_LAYER_TYPES_SUCCESS = "LOAD_LAYER_TYPES_SUCCESS";
export const LOAD_PREFERENCES_SUCCESS = "LOAD_PREFERENCES_SUCCESS";
export const UPDATE_PREFERENCES_SUCCESS = "UPDATE_PREFERENCES_SUCCESS";
export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const SELECT_LAYER = "SELECT_LAYER";
export const SELECT_LAYERS = "SELECT_LAYERS";
export const DESELECT_LAYER = "DESELECT_LAYER";
export const DESELECT_LAYERS = "DESELECT_LAYERS";
export const SET_PREFERENCE_MODE = "SET_PREFERENCE_MODE";
export const SET_SELECTED_LEGEND_ITEM = "SET_SELECTED_LEGEND_ITEM";
export const LOAD_GROUPS_SUCCESS = "LOAD_GROUPS_SUCCESS";
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const INITIALIZE_COMPRESSED_NETWORK = "INITIALIZE_COMPRESSED_NETWORK";
export const LOAD_LEGEND_PREFERENCES_SUCCESS =
  "LOAD_LEGEND_PREFERENCES_SUCCESS";
export const UPDATE_LEGEND_PREFERENCES_SUCCESS =
  "UPDATE_LEGEND_PREFERENCES_SUCCESS";
export const SET_SELECTION_COLOR_MODE = "SET_SELECTION_COLOR_MODE";
export const SET_GENERATION_COLOR_MODE = "SET_GENERATION_COLOR_MODE";
export const SET_NETWORK_BBOX = "SET_NETWORK_BBOX";
export const SET_LEGEND_BBOX = "SET_LEGEND_BBOX";
export const UPDATE_ALERT_SNACK_SUCCESS = "UPDATE_ALERT_SNACK_SUCCESS";
